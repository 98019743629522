import InputSelect, { InputSelectProps } from '../input/InputSelect';
import { useEffect } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';

export type ZodFieldSelectProps = Omit<InputSelectProps, 'onChange' | 'name'> & { name: string };

export default function ZodFieldSelect(props: ZodFieldSelectProps) {
  const [name, value, setValue] = useZodFormFieldSingle(props.name);

  useEffect(() => {
    const enabled = props.options.filter((o) => o.disabled !== true);

    if (
      enabled[0]?.value &&
      ((enabled.length === 1 && enabled[0].value !== value) ||
        (!props.placeholder && !value) ||
        (value && !enabled.some((e) => e.value === value)))
    ) {
      setValue(enabled[0].value.toString());
    }
  }, [props.options, value]);

  return <InputSelect {...props} name={name} value={value} onChange={(value) => setValue(value || '')} />;
}
